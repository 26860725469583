import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import { registerLocaleData } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { CookieModule } from "ngx-cookie";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./token.interceptor";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NdbxIconModule } from '@allianz/ngx-ndbx/icon';
registerLocaleData(localeDe, "de");

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    CookieModule.forRoot(),
    SlickCarouselModule,
    NdbxIconModule
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
