<header #header [ngClass]="{ sticky: isSticky }" class="{{ isClass }}">
  <app-overlay *ngIf="!isAuthenticated()"></app-overlay>
  <div nxLayout="grid maxwidth" role="none">
    <div nx-header role="none">
      <div class="brand-container">
        <nx-header-brand class="{{ glocknerCls }}">
          <nx-link>
            <a [routerLink]='logoRedirection' title="{{ logoAlt }}" role="link" tabindex="0" *ngIf="isConnex;else homePageUri">
              <figure nxFigure>
                <img src="{{ logoSrc }}" alt="{{ logoAlt }}" role="img" class="{{ glocknerCls }}"
                  [ngClass]="{ connex: isConnex, mobileLogo: isMobileLogo }" />
              </figure>
            </a>
            <ng-template #homePageUri>
              <a [href]='homePageUrl()' title="{{ logoAlt }}" role="link" tabindex="0">
                <figure nxFigure>
                  <img src="{{ logoSrc }}" alt="{{ logoAlt }}" role="img" class="{{ glocknerCls }}"
                    [ngClass]="{ connex: isConnex, mobileLogo: isMobileLogo }" />
                </figure>
              </a>
            </ng-template>
          </nx-link>
        </nx-header-brand>
        <div class="header-link--item cooperation" *ngIf="isConnex">
          <nx-link id="cooperation-link">
            <a routerLink='/uebersicht'>
              In Kooperation mit
            </a>
          </nx-link>
          <nx-link>
            <a routerLink='/uebersicht' title="Link for main Allianz site" role="link" tabindex="0">
              <figure nxFigure>
                <img src="assets/images/logo.svg" alt="Allianz Global Brand Logo" role="img" />
              </figure>
            </a>
          </nx-link>
        </div>
      </div>
      <div *ngIf="!isMobile; else mobileMenu" role="none" class="header--desktop">
        <nx-header-actions *ngIf="!noHeader">
          <ul class="header-links" [ngClass]="{ 'isIE-browser': isIEBrowser() }">
            <li class="header-link--item reward">
              <nx-link id="remainingPoints">
                <a routerLink="/punktekonto">
                  {{ userRewardPoints }}
                </a>
              </nx-link>
              <span role="none">{{ rewardUOM | titlecase }}</span>
            </li>
            <li class="header-link--item pipe"></li>
            <li class="header-link--item login">
              <div role="button" [nxPopoverTriggerFor]="authPopover" nxPopoverDirection="bottom"
                nxPopoverTrigger="click" nxPopoverCloseable="false" nxPopoverScrollStrategy="reposition" tabindex="0">
                <span class="popover-text">
                  <span class="popover-username" id="member-fullname"><div><span>{{ fullName.substr(0,21) }}{{ fullName.length > 22 ? "..." : "" }}</span></div></span>
                </span>
                <nx-icon name="chevron-down" class="nx-margin-left-xs" size="s"></nx-icon>
              </div>
              <nx-popover #authPopover>
                <div class="login-popup" role="tree">
                  <img class="allianz-logo" src="assets/images/main-alliance.png" alt="The Main Allianz Logo"
                    role="img" />
                  <p nxCopytext="normal">
                    Ihr Online-Portal rund um Ihre Verträge – persönlich und
                    sicher.
                  </p>

                  <div class="extra-padding">
                    <nx-link nxStyle="black">
                      <a href="{{ redirectForAccountSummary() }}">
                        <button nxButton="primary medium block " class="nx-margin-bottom-xs" tabindex="-1">
                          KONTOŪBERSICHT
                        </button></a>
                    </nx-link>

                    <button nxButton="tertiary medium block " (click)="signoutHandler($event)">
                      ABMELDEN
                    </button>
                  </div>
                </div>
              </nx-popover>
            </li>
          </ul>
        </nx-header-actions>
      </div>

      <ng-template #mobileMenu>
        <div class="header--mobile" role="none" *ngIf="!noHeader">
          <ul>
            <li (click)="toggleMenu('info')">
              <nx-icon name="bars" size="s" *ngIf="!isMenuOpen"></nx-icon>
              <nx-icon name="close" size="s" *ngIf="isMenuOpen"></nx-icon>
            </li>
          </ul>
          <div class="collaspeable" #mobileCollaspable [ngClass]="{ open: isMenuOpen }">
            <div class="menu" *ngIf="!showInfoMenu">
              <nx-formfield aria-label="Input Search Field">
                <input #mobileSearch nxInput aria-autocomplete="list" (change)="searchResult($event)"
                  placeholder="Suchbegriff eingeben... " title="Input Search Field" />
                <span nxFormfieldSuffix role="none">
                  <button nxButton="primary small" (click)="searchClickHandler($event)">
                    Suchen
                  </button>
                </span>
              </nx-formfield>
              <div>
                <p nxCopytext="large" class="nx-margin-down-2xs">
                  Mein Vorteilsprogramm
                </p>
                <nx-link>
                  <a routerLink="/punkte_einloesen" routerLinkActive="is-active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <p nxCopytext="large">Punkte einlösen</p>
                  </a>
                </nx-link>
                <nx-link>
                  <a routerLink="/einkaufsvorteile" [routerLinkActiveOptions]="{ exact: true }"
                    routerLinkActive="is-active">
                    <p nxCopytext="large">Einkaufsvorteile</p>
                  </a>
                </nx-link>
                <nx-link>
                  <a routerLink="/ticketshop" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="is-active">
                    <p nxCopytext="large">Ticketshop</p>
                  </a>
                </nx-link>
                <nx-link>
                  <a routerLink="/so_funktionierts" routerLinkActive="is-active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <p nxCopytext="large">So funktioniert's</p>
                  </a>
                </nx-link>
                <nx-link>
                  <a routerLink="/punktekonto" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="is-active">
                    <p nxCopytext="large">Punktekonto</p>
                  </a>
                </nx-link>
              </div>
            </div>
            <div class="info" *ngIf="showInfoMenu">
              <div class="mobile--login-popup" role="tree">
                <img class="allianz-logo" src="assets/images/main-alliance.png" alt="The Main Allianz Logo" />
                <p nxCopytext="normal">
                  Ihr Online-Portal rund um Ihre Verträge – persönlich und
                  sicher.
                </p>
                <nx-link nxStyle="black">
                  <a href="{{ redirectForAccountSummary() }}">
                    <button nxButton="primary medium block " class="nx-margin-bottom-xs" tabindex="-1">
                      KONTOŪBERSICHT
                    </button></a>
                </nx-link>
                <button nxButton="tertiary medium block " (click)="signoutHandler($event)">
                  ABMELDEN
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</header>