import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, shareReplay } from "rxjs/operators";
import UTILS from "src/app/shared/utils/utils";
import { API_URL } from "src/environments/environment";
import { EMPTY } from "rxjs";
import { delayedRetry } from "./utils.service";
@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(private http: HttpClient) {}

  maintenanceShared(): Promise<any> {
    var device: any = "desktop";
    if (UTILS.detectMobile()) {
      device = "mobile";
    }
    const response = this.http
      .get<any>(`${API_URL}/maintenance/status?orientation=${device}`)
      .toPromise();
    return response;
  }

  getFooterLinks(slug: any) {
    return this.http.get<any>(`${API_URL}/menu/${slug}`).pipe(
      delayedRetry(1500, 5),
      catchError(() => {
        return EMPTY;
      }),
      shareReplay()
    );
  }
  getOverlayDetails() {
    return this.http.get<any>(`${API_URL}/overlay`).pipe(
      delayedRetry(1500, 5),
      catchError(() => EMPTY),
      shareReplay()
    );
  }

  addToWishlist(id: any) {
    return this.http.post<any>(`${API_URL}/wishlist/add?_format=json`, {
      product_id: id,
    });
  }

  deleteToWishlist(id: any) {
    return this.http.delete<any>(`${API_URL}/wishlist/delete/${id}`);
  }

  getPointScore() {
    try {
      return this.http.get<any>(`${API_URL}/member/points`).pipe(
        delayedRetry(1500, 5),
        catchError(() => EMPTY),
        shareReplay()
      );
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  getUserFullDetails() {
    try {
      return this.http.get<any>(`${API_URL}/member/details`).pipe(
        delayedRetry(1500, 5),
        catchError(() => EMPTY),
        shareReplay()
      );
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  subscribeUnsubscribe(body: any) {
    return this.http.post<any>(`${API_URL}/stock/alert`, body);
  }

  getTotalCountInCart() {
    try {
      return this.http.get<any>(`${API_URL}/cart/count`).pipe(
        delayedRetry(1500, 5),
        catchError(() => EMPTY),
        shareReplay()
      );
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  getMMUserDetails() {
    try {
      return this.http.get<any>(`${API_URL}/mmuser`).pipe(
        delayedRetry(1500, 5),
        catchError(() => EMPTY),
        shareReplay()
      );
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  closeOverlay() {
    return this.http.get<any>(`${API_URL}/overlay/close`);
  }

  expireReservation() {
    return this.http.post<any>(
      `${API_URL}/ticketshop/cart/reservation/expire`,
      {}
    );
  }

  registerOverlayBlockCtaEvent() {
    return this.http.post<any>(`${API_URL}/overlay/cta/add`, {});
  }

  registerEsbCtaEvent() {
    return this.http.post<any>(`${API_URL}/esb/cta/add`, {});
  }

  registerWeweCtaEvent() {
    return this.http.post<any>(`${API_URL}/wewe/cta/add`, {});
  }
}
