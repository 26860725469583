export const USER_DETAILS = "USER_DETAILS";
export const ADDRESS_INFO = "ADDRESS_INFO";
export const IS_SUCCESS = "IS_SUCCESS";
export const REDIRECT_PATHNAME = "REDIRECT_PATHNAME";
export const MEMBER_POINT = "total-points";
export const USER_FULL_DETAILS = "user-details";
export const LOTTERY_USER_ADDRESS = "al-lottery-address";
export const LOTTERY_USER_ANSWER = "al-lottery-answer";
export const CHECKOUT_ABORTED = "CHECKOUT_ABORTED";
export const ONE_TRUST_CONSENT_CATEGORIES = {
    PERFORMANCE: 'performance',
    TARGETING: 'targeting',
};
