<footer aria-label="footer" role="contentinfo">
  <div nxLayout="grid maxwidth" role="navigation" aria-label="footer navigation">
    <div nxRow>
      <div nxCol="12">
        <div nx-footer role="none" [ngClass]="{ connex: isConnex, prospectfooter: !isRegularUser && isConnex }">
          <nx-footer-copyright *ngIf="!isConnex" [innerHTML]="copyrightText"></nx-footer-copyright>
          <nx-footer-navigation [ngClass]="{ connex: isConnex }">
            <nx-footer-link *ngFor="let link of links">
              <a routerLink="{{ link?.menu_url }}" *ngIf="link.internal; else externalLink">{{ link?.menu_title }}</a>
              <ng-template #externalLink>
                <a href="{{ link?.menu_url }}" target="_blank">{{
                  link?.menu_title
                }}</a>
              </ng-template>
            </nx-footer-link>
          </nx-footer-navigation>
        </div>
      </div>
    </div>
  </div>
</footer>