import { Injectable } from "@angular/core";

import { AuthDrupalService } from "./auth-drupal.service";
import {
  TOKEN,
  CIM,
  environment,
  STATIC_URL,
  notInPrimeCloud,
  DRUPAL_BASE_URL,
} from "src/environments/environment";
import { Router } from "@angular/router";
import UTILS from "src/app/shared/utils/utils";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private drupalService: AuthDrupalService, public router: Router) {
    let pathName = window.location.pathname;
    if (this.listPathname(pathName)) {
      localStorage.setItem("tempPathname", String(pathName));
    } else {
      localStorage.setItem("tempPathname", null);
    }
  }
  /**
   * @description: Get the Code from the CIM and authenticate user and get the JWT from Drupal for further connectivity
   * @private
   * @returns: Status weather user is authenticated or not
   * @memberof AuthService
   */
  private async handleUserAuth(): Promise<any> {
    localStorage.setItem("maintenance", null);
    sessionStorage.removeItem("INVALID_USER");
    const jwtToken = sessionStorage.getItem(TOKEN.avp_token);
    const urlParams = new URLSearchParams(window.location.search);

    const getCSRFToken = await this.getCSRFToken();
    sessionStorage.setItem("CSRF-TOKEN", getCSRFToken);
    const maintenance = await this.maintenanceStatus();

    if (maintenance) {
      console.log("Session Clear");
      sessionStorage.clear();
      return true;
    }

    var userDetails = sessionStorage.getItem("userDataId");

    if (environment.production && !!userDetails) {
      const { userId } = JSON.parse(userDetails);
      try {
        const keepAlive = await this.drupalService.checkKeepAlive(userId);
        console.log(keepAlive, "keepAlive");
      } catch (e) {
        let token = sessionStorage.getItem(TOKEN.avp_token);
        if (token) {
          sessionStorage.clear();
          return false;
        }
      }
    }

    if (urlParams.has("code")) {
      if (!!jwtToken) {
        return this.isJwtValid(jwtToken);
      }
      sessionStorage.clear();
      const code = urlParams.get("code");
      return this.isAuthCodeValid(code);
    }

    if (!!jwtToken) {
      return this.isJwtValid(jwtToken);
    }
    return !environment.production;
  }

  private async isJwtValid(token: string) {
    try {
      const res = await this.drupalService.validateJWT(token);

      const { status } = res;
      if (!!status && status === "success") {
        return true;
      } else {
        sessionStorage.clear();
        return false;
      }
    } catch (error) {
      sessionStorage.clear();
      console.log("INVALID_JWT", error);
      return false;
    }
  }

  private async isAuthCodeValid(code: string) {
    try {
      const response = await this.drupalService.validateAuthCode(code);
      const { data, status } = response;
      console.log("AUTH_RES", response);
      if (!!status && status === "success") {
        const { token } = data;
        sessionStorage.setItem(TOKEN.avp_token, token);
        sessionStorage.setItem("userDataId", JSON.stringify(data));
        sessionStorage.removeItem("INVALID_USER");
        const __urlParams = new URLSearchParams(window.location.search);
        if (__urlParams.has("state")) {
          const _state = __urlParams.get("state");
          if (!!_state) {
            sessionStorage.setItem("redirectUrl", String(_state));
          }
        }

        if (!sessionStorage.getItem("avp")) {
          sessionStorage.setItem("avp", "true");
          location.reload();
        }
        return true;
      } else if (status === 302) {
        window.location.href = `${window.location.origin}/blocked`;
        sessionStorage.setItem("INVALID_USER", "true");
        sessionStorage.clear();
        return true;
      } else {
        sessionStorage.setItem("INVALID_USER", "true");
        sessionStorage.clear();
        window.location.href = notInPrimeCloud;
        return true;
      }
    } catch (error) {
      sessionStorage.setItem("INVALID_USER", "true");
      console.log("AUTH_CODE_INVALID", error);
      sessionStorage.clear();
      if (error && error.status === 302) {
        window.location.href = `${window.location.origin}/blocked`;
      } else {
        window.location.href = notInPrimeCloud;
      }
      return true;
    }
  }

  public isUserAuthenticated() {
    return this.handleUserAuth();
  }

  public async logOutUser() {
    const response = await this.drupalService.invalidateDrupalSession();
    const { oidc_token, status } = response;
    if (!!status && status === "success") {
      let uri = CIM.logoutUrl(oidc_token);
      try {
        const logout = await this.drupalService.expireCIMSession(uri);
        console.log(logout);
        window.location.href = CIM.post_logout_redirect_uri;
      } catch (e) {
        console.log(e);
        window.location.href = CIM.post_logout_redirect_uri;
      }
    }
  }

  private async maintenanceStatus() {
    try {
      const res = await this.drupalService.maintenanceStatus();
      const { status, all, overview, global_maintenance } = res;
      if (status === "success") {
        if (all.status) {
          sessionStorage.setItem("maintenanceMessage", String(all.message));
        }
        localStorage.setItem("maintenance", JSON.stringify(res));
        if (global_maintenance.status) {
          return true;
        }
        return false;
      } else {
        localStorage.setItem("maintenance", null);
      }
      return false;
    } catch (error) {
      localStorage.setItem("maintenance", null);
      return false;
    }
  }

  private async getCSRFToken() {
    let response = await fetch(`${DRUPAL_BASE_URL}/session/token`);
    let data = await response.text();
    return data;
  }

  private listPathname(pathName) {
    let pathArray = [
      "punkte_einloesen",
      "einkaufsvorteile",
      "ticketshop-list",
      "wartungsarbeiten",
      "so_funktionierts",
      "punktekonto",
      "auschecken",
      "warenkorb",
      "coupon",
      "voucher",
      "ticketshop",
      "newsletter-abonnieren",
      "ticketshop-warenkorb",
      "ticketshop-checkout",
      "uebersicht",
      "newsletter-abbestellen",
      "gewinnspiel",
    ];
    return pathArray.findIndex((i) => pathName.includes(i)) !== -1;
  }
}
