import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NxAccordionModule } from "@aposin/ng-aquila/accordion";
import { NxHeaderModule } from "@aposin/ng-aquila/header";
import { NxLinkModule } from "@aposin/ng-aquila/link";
import { NxIconModule } from "@aposin/ng-aquila/icon";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxSliderModule } from "@aposin/ng-aquila/slider";
import { NxHeadlineModule } from "@aposin/ng-aquila/headline";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { NxFooterModule } from "@aposin/ng-aquila/footer";
import { NxCardModule } from "@aposin/ng-aquila/card";
import { NxCopytextModule } from "@aposin/ng-aquila/copytext";
import { NxPopoverModule } from "@aposin/ng-aquila/popover";
import { NxSpinnerModule } from "@aposin/ng-aquila/spinner";
import { NxNumberStepperModule } from "@aposin/ng-aquila/number-stepper";
import { NxRadioToggleModule } from "@aposin/ng-aquila/radio-toggle";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxAutocompleteModule } from "@aposin/ng-aquila/autocomplete";
import { NxPageSearchModule } from "@aposin/ng-aquila/page-search";
import { NxMessageModule } from "@aposin/ng-aquila/message";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import { NxSwitcherModule } from "@aposin/ng-aquila/switcher";
import { NxRadioModule } from "@aposin/ng-aquila/radio-button";
import { NxCircleToggleModule } from "@aposin/ng-aquila/circle-toggle";
import { NxPaginationModule } from "@aposin/ng-aquila/pagination";
import { NxDialogService,NxModalModule ,NxModalService } from "@aposin/ng-aquila/modal";
import { NxTabsModule } from "@aposin/ng-aquila/tabs";
import { NxBadgeModule } from "@aposin/ng-aquila/badge";
import { NxTableModule } from "@aposin/ng-aquila/table";
import { NxProgressStepperModule } from "@aposin/ng-aquila/progress-stepper";
import { NxtBankIbanModule } from "@nxt/ngx-ndbx-extensions/bank-iban";
import { NxMaskModule } from '@aposin/ng-aquila/mask';

import { NavbarComponent } from "./components/navbar/navbar.component";
import { FAQNavbarComponent } from "./components/faq-navbar/faq-navbar.component";
import { FooterTopComponent } from "./components/footer-top/footer-top.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeroSectionComponent } from "./components/hero-section/hero-section.component";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { MessageBoxComponent } from "./components/message-box/message-box.component";
import { BreadCrumbsComponent } from "./components/bread-crumbs/bread-crumbs.component";
import { ComingSoonComponent } from "./components/coming-soon//coming-soon.component";
import { NxDialogServiceComponent } from "./components/nx-dialog-service/nx-dialog-service.component";
import { SharedService } from "./services/shared.service";
import { FAQBodySectionComponent } from "./components/faq-body-section/faq-body-section.component";

import { SlickCarouselModule } from "ngx-slick-carousel";
import { MediaCarouselComponent } from "./components/media-carousel/media-carousel.component";
import { ProductCardComponent } from "./components/product-card/product-card.component";
import { ImageCardComponent } from "./components/image-card/image-card.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { CheckoutDetailsComponent } from "./components//checkout-details/checkout-details.component";
import { StepperStaticComponent } from "./components/stepper-static/stepper-static.component";
import { CarouselLightBoxComponent } from "./components/carousel-litebox/carousel-lightbox.component";
import { CouponLightBoxComponent } from "./components/coupon-lightbox/coupon-lightbox.component";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    OverlayComponent,
    MessageBoxComponent,
    BreadCrumbsComponent,
    NavbarComponent,
    FAQNavbarComponent,
    FAQBodySectionComponent,
    FooterTopComponent,
    ComingSoonComponent,
    NxDialogServiceComponent,
    HeroSectionComponent,
    MediaCarouselComponent,
    ProductCardComponent,
    ImageCardComponent,
    LoaderComponent,
    CheckoutDetailsComponent,
    StepperStaticComponent,
    CarouselLightBoxComponent,
    CouponLightBoxComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NxAccordionModule,
    NxHeaderModule,
    NxLinkModule,
    NxIconModule,
    NxGridModule,
    NxSliderModule,
    NxHeadlineModule,
    NxButtonModule,
    NxFooterModule,
    NxCardModule,
    NxCopytextModule,
    NxPopoverModule,
    NxSpinnerModule,
    NxNumberStepperModule,
    NxRadioToggleModule,
    NxInputModule,
    NxAutocompleteModule,
    SlickCarouselModule,
    NxMessageModule,
    NxPageSearchModule,
    NxFormfieldModule,
    NxDropdownModule,
    NxSwitcherModule,
    NxRadioModule,
    NxCircleToggleModule,
    NxPaginationModule,
    NxTabsModule,
    NxBadgeModule,
    NxTableModule,
    NxProgressStepperModule,
    NxtBankIbanModule,
    NxMaskModule,
    NxModalModule
  ],
  exports: [
    NxAccordionModule,
    NxIconModule,
    HeaderComponent,
    FooterComponent,
    OverlayComponent,
    MessageBoxComponent,
    BreadCrumbsComponent,
    NavbarComponent,
    FAQNavbarComponent,
    FAQBodySectionComponent,
    HeroSectionComponent,
    FooterTopComponent,
    ComingSoonComponent,
    NxDialogServiceComponent,
    MediaCarouselComponent,
    ProductCardComponent,
    CheckoutDetailsComponent,
    StepperStaticComponent,
    CarouselLightBoxComponent,
    CouponLightBoxComponent,
    ImageCardComponent,
    NxGridModule,
    NxSliderModule,
    NxHeadlineModule,
    NxLinkModule,
    NxButtonModule,
    NxCopytextModule,
    NxSpinnerModule,
    NxNumberStepperModule,
    NxRadioToggleModule,
    NxInputModule,
    NxAutocompleteModule,
    NxCardModule,
    SlickCarouselModule,
    NxMessageModule,
    NxPageSearchModule,
    NxFormfieldModule,
    NxDropdownModule,
    NxSwitcherModule,
    NxRadioModule,
    NxCircleToggleModule,
    NxPaginationModule,
    NxTabsModule,
    NxBadgeModule,
    NxTableModule,
    NxProgressStepperModule,
    NxtBankIbanModule,
    NxMaskModule,
    LoaderComponent,
    NxModalModule
  ],
  providers: [SharedService, NxDialogService,NxModalService],
})
export class SharedModule {}
