<ng-template #ngIfModalBody>
  <nx-loader *ngIf="isLoading"></nx-loader>
  <div class="u-text-center modal--main regular-user-overlay"
    *ngIf="ifoverlay && overlayDetails.id == 2; else isProspective">
    <div role="none">
      <img src="{{ overlayDetails.image.src }}" class="modal--image" role="img" alt="{{ overlayDetails.image.alt }}" />
    </div>
    <h3 class="modal--title">{{ overlayDetails.title }}</h3>
    <p class="modal--body" [innerHTML]="overlayDetails.body"></p>
    <nx-link nxStyle="negative" class="nx-margin-bottom-s">
      <button class="modal--button" nxButton="block primary" type="button" tabindex="-1" (click)="registerOverlayBlockClick()">
        {{ overlayDetails.cta_link.title }}
      </button>
    </nx-link>
    <nx-link class="nx-margin-bottom-2xs" nxStyle="text">
      <a routerLink="/" (click)="closeModalhandler()"><button class="modal--button" nxButton="block tertiary"
          type="button" tabindex="-1">
          WEITER
        </button></a>
    </nx-link>
  </div>
  <ng-template #isProspective>
    <div nxModalContent class="prospective-user-overlay">
      <div class="u-text-center modal--main">
        <h3 class="modal--title">{{ overlayDetails.title }}</h3>
        <p class="modal--body" [innerHTML]="overlayDetails.body"></p>
      </div>
      <nx-link nxStyle="block">
        <a [routerLink]="[]" (click)="closeModalhandler(true)">
          <nx-icon name="arrow-right"></nx-icon>
          {{ overlayDetails.cta_link.title }}
        </a>
      </nx-link>
    </div>
  </ng-template>
</ng-template>
<!-- <nx-modal #basicModal (nxClose)="closeModalhandler()" [modalBody]="ngIfModalBody" [showCloseIcon]="true"
  *ngIf="modalOpen" class="{{ isModalClass }}">
</nx-modal> -->