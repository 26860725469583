import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { TOKEN, CIM, DRUPAL_BASE_URL } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { method, url } = request;
    const jwtToken = sessionStorage.getItem(TOKEN.avp_token);
    const drupalToken = sessionStorage.getItem("CSRF-TOKEN");
    const tokenLess = this.isTokenLess(url);
    if (request.url.includes("custom/keepalive.jsp")) {
      request = request.clone({
        withCredentials: true,
      });
      return next.handle(request);
    }

    if (url.includes("newsletter/decryption")) {
      request = request.clone({
        setHeaders: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${CIM.redirect_uri}`,
        },
      });
      return next.handle(request);
    }

    if (method === "POST" || method === "PUT") {
      this.getUserAsync()
        .then((res) => {
          sessionStorage.setItem("CSRF-TOKEN", res);
        })
        .catch((err) => {
          console.log(err);
        });

      if (
        url.includes("validate/token") ||
        url.includes("validate/user") ||
        url.includes("newsletter/unsubscribe")
      ) {
        request = request.clone({
          setHeaders: {
            "X-CSRF-Token": `${drupalToken}`,
          },
        });
        return next.handle(request);
      } else {
        request = request.clone({
          setHeaders: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${CIM.redirect_uri}`,
            Authorization: `Bearer ${jwtToken}`,
            "X-CSRF-Token": `${drupalToken}`,
            provider_id: "jwt_auth",
          },
        });
        return next.handle(request);
      }
    } else {
      if (!tokenLess) {
        request = request.clone({
          setHeaders: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${CIM.redirect_uri}`,
            Authorization: `Bearer ${jwtToken}`,
            provider_id: "jwt_auth",
          },
        });
      }
      return next.handle(request);
    }
  }

  private isTokenLess(url: string) {
    if (
      url.includes("maintenance/status") ||
      url.includes("custom/keepalive.jsp") ||
      url.includes("generate/token") ||
      url.includes("newsletter/unsubscribe") ||
      url.includes("realms/eu1/connect/endSession") ||
      url.includes("newsletter/decryption") ||
      url.includes("menu/connex-footer") ||
      url.includes("menu/footer") ||
      url.includes("menu/glockner-footer")
    ) {
      return true;
    } else {
      return false;
    }
  }

  private async getUserAsync() {
    let response = await fetch(`${DRUPAL_BASE_URL}/session/token`);
    let data = await response.text();
    return data;
  }
}

function authenticateUser(user, password) {
  var token = user + ":" + password;

  // Should i be encoding this value????? does it matter???
  // Base64 Encoding -> btoa
  var hash = btoa(token);

  return "Basic " + hash;
}
