import { ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TOKEN } from "src/environments/environment";
import { SharedService } from "../../services/shared.service";
import UTILS from "src/app/shared/utils/utils";
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';

type MyDialogResult = 'proceed' | 'cancel';


@Component({
  selector: "app-overlay",
  templateUrl: "./overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OverlayComponent implements OnInit {
  @ViewChild('ngIfModalBody') templateRef!: TemplateRef<any>;

  dialogRef?: NxModalRef<any, MyDialogResult | undefined>; // cancel and backdrop click return undefined

  actionResult?: MyDialogResult;
  links: any;
  modalOpen: boolean = false;
  overlayDetails: any = {};
  ifoverlay: boolean = false;
  isLoading: boolean = false;
  isModalClass: any = "regularModal";
  isToken: any;
  constructor(private router: Router, private api: SharedService,private readonly dialogService: NxDialogService,
    private readonly _cdr: ChangeDetectorRef,) {}

    openFromTemplate(): void {
      this.dialogRef = this.dialogService.open(this.templateRef, {
          ariaLabel: 'A simple modal',
          showCloseIcon: true,
      });

      this.dialogRef.afterClosed().subscribe(result => {
          this.actionResult = result;
          sessionStorage.setItem('overlayShown', 'true');

          this._cdr.markForCheck();
      });
  }

  closeDialog(result: MyDialogResult): void {
      this.dialogRef?.close(result);
  }
  ngOnInit() {
    this.checkAndShowOverlay();
    
  }
  checkAndShowOverlay() {
    if (!sessionStorage.getItem('overlayShown')) {
      this.getOverlayDetails();  
    }
  }

  getOverlayDetails(result :MyDialogResult ="cancel") {
    this.api.getOverlayDetails().subscribe(
      (response) => {
        if (response.hasOwnProperty("data") && response.status === "success") {
          const { data } = response;
          const { id } = data;
          if (id != 2) {
            this.isModalClass = "prospectiveCls";
          }
          this.overlayDetails = data;
          if (Number(data.visibility_status)) {
            this.ifoverlay = true;
            this.openFromTemplate();
          } else {
            this.ifoverlay = false;
            this.closeDialog(result);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  closeModalhandler(redirectToCta: boolean = false,result :MyDialogResult="cancel") {
    this.closeDialog(result);
    sessionStorage.setItem("overlay", "true");
    this.api.closeOverlay().subscribe(
      (response) => {
        console.log(response);
        if (redirectToCta) {
          this.redirectToOverlayBlockCta();
        }
      },
      (error) => {
        console.log(error);
        if (redirectToCta) {
          this.redirectToOverlayBlockCta();
        }
      }
    );
  }

  registerOverlayBlockClick() {
    this.isLoading = true;
    this.api.registerOverlayBlockCtaEvent().subscribe(
      (response) => {
        console.log(response);
        this.closeModalhandler(true);
      },
      (error) => {
        console.log(error);
        this.closeModalhandler(true);
      }
    );
  }

  redirectToOverlayBlockCta() {
    const isExternalLink = /http:\/\/|https:\/\//.test(this.overlayDetails.cta_link.url)
        ? true
        : false;
    if (isExternalLink) {
      window.location.href = this.overlayDetails.cta_link.url
    } else {
      this.router.navigate([this.overlayDetails.cta_link.url])
    }
  }
}
