import * as moment from "moment";
import {
  MEMBER_POINT,
  USER_FULL_DETAILS,
  ONE_TRUST_CONSENT_CATEGORIES
} from "src/app/shared/constants/constants";
import { environment } from "src/environments/environment";

const UTILS = {
  detectMobile() {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      return true;
    }
  },
  detectTab() {
    var userAgent = navigator.userAgent.toLowerCase();
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  },
  getQueryStringFromObject(obj: any) {
    const queryString = Object.keys(obj)
      .map((key) => key + "=" + obj[key])
      .join("&");
    return queryString;
  },
  debounce(func, wait = 100, immediate = false) {
    let timeout;
    return function () {
      const context = this,
        args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  },

  isMaintenance(key) {
    const obj = this.getMaintenanceObject();
    var getMMuser = sessionStorage.getItem("mmuser");
    if (obj.global_maintenance.status) {
      sessionStorage.setItem(
        "maintenanceMessage",
        obj.global_maintenance.message
      );
      return true;
    } else if (!!getMMuser && /true/.test(getMMuser)) {
      return false;
    }
    sessionStorage.setItem("maintenanceMessage", obj[key].message);
    if (obj.all.status || obj[key].status) {
      return true;
    }
    return false;
  },

  getMaintenanceObject() {
    let mode = localStorage.getItem("maintenance");
    if (!!mode) {
      return JSON.parse(mode);
    }
    return false;
  },

  historyPush(data, returnStr = false) {
    if (!!data) {
      let params = Object.keys(data)
        .map((key) => {
          if (Array.isArray(data[key])) {
            return key + "=" + data[key].join(",");
          }
          return key + "=" + data[key];
        })
        .join("&");
      if (!returnStr) {
        window.history.pushState(
          null,
          null,
          window.location.pathname + "?" + params
        );
      } else {
        return params;
      }
    }
  },

  captionManagement(d) {
    let { stock } = d;
    if (stock != undefined && stock <= 0) {
      return { message: `Derzeit nicht auf Lager`, class: "expired" };
    }
    const { isOutOfStock, isAvailability, isStock } = CARD_FLAP();
    const flaPAvailability = isAvailability(d);
    if (flaPAvailability) {
      return flaPAvailability;
    }
    const outOFStock = isOutOfStock(d);
    if (outOFStock) {
      return outOFStock;
    }
    if (d.hasOwnProperty("flap_status") && !d.flap_status) {
      return { message: false, class: "" };
    }
    const flaPStock = isStock(d);
    if (flaPStock) {
      return flaPStock;
    }

    return { message: false, class: "" };
  },

  onScrollOffset(offset, position) {
    return position > offset;
  },
  convertToEuropeCurrency(amount: any) {
    var amt = Number(amount).toFixed(2);
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    })
      .format(Number(amt))
      .replace(" €", "");
  },

  roundOfPoints(amount: any) {
    var x = Math.round(amount);
    return new Intl.NumberFormat("de-DE").format(x);
  },

  errorHandling(exception: any) {
    if (typeof exception == "object" && exception.hasOwnProperty("error")) {
      if (
        typeof exception.error == "object" &&
        exception.error.hasOwnProperty("error")
      ) {
        if (!/<\/?[a-z][\s\S]*>/i.test(exception.error.error))
          return exception.error.error;
      }
      if (!/<\/?[a-z][\s\S]*>/i.test(exception.error)) return exception.error;
    }
    return "Etwas ist schief gelaufen. Bitte versuche es erneut";
  },

  rolePersonType() {
    const userDetails = sessionStorage.getItem(USER_FULL_DETAILS);
    if (userDetails) {
      const jsonParse = JSON.parse(userDetails);
      const { properties } = jsonParse;
      if (properties) {
        const { PersonType } = properties;
        return PersonType;
      }
    }
    return "";
  },

  isProspective() {
    let role = this.rolePersonType();
    if (role) {
      if (role === "PROSPECTIVE" || role === "CANDIDATE") {
        return true;
      }
    }
    return false;
  },

  isIEBrowser() {
    let ie = !!document["documentMode"];

    if (navigator.userAgent.indexOf("MSIE") != -1 || ie == true) {
      return true;
    }
    return false;
  },

  getUserMemberPoints() {
    return sessionStorage.getItem(MEMBER_POINT);
  },

  removeSpecialCharacters: function (string: string) {
    // Replace German characters like ä,ö,ü,Ä,Ö,Ü with their international versions.
    string = string.replace(/[ÄäÖöÜüß]/g, function (match) {
      switch (match) {
        case 'Ä': return 'Ae';
        case 'ä': return 'ae';
        case 'Ö': return 'Oe';
        case 'ö': return 'oe';
        case 'Ü': return 'Ue';
        case 'ü': return 'ue';
        case 'ß': return 'ss';
      }
    });
    // Remove all whitespaces with single space.
    string = string.replace(/\s+/g, ' ');
    // Replace other special characters with _.
    string = string.replace(/[^a-zA-Z0-9 _-]/gi, '_');
    return string;
  },

  getATLEnvironmentName() {
    const angularEnvironment = environment.name;
    const ATLAngularEnvironmentMapping = {
      local: 'dev',
      dev: 'dev',
      pen: 'stage',
      prod: 'prod'
    }
    return ATLAngularEnvironmentMapping[angularEnvironment];
  },

  getOneTrustConsent(category: string) {
    // Don't check consent for non-prod instances.
    if (environment.name !== 'prod') {
      return true;
    }
    const oneTrustConsentCategories = {
      [ONE_TRUST_CONSENT_CATEGORIES.PERFORMANCE]: '2',
      [ONE_TRUST_CONSENT_CATEGORIES.TARGETING]: '4'
    };
    // @ts-ignore
    if (window.OnetrustActiveGroups && typeof window.OnetrustActiveGroups === 'string' ) {
      // @ts-ignore
      const onetrustActiveGroups = window.OnetrustActiveGroups.split(',');
      if (onetrustActiveGroups.includes(oneTrustConsentCategories[category])) {
        return true;
      }
    }
    return false;
  },

  getDateDiff(startDate: string, endDate: string, unit: any = "days") {
    const startDateObject = moment(new Date(startDate));
    const endDateObject = moment(new Date(endDate));
    return startDateObject.diff(endDateObject, unit);
  },

  modifyActiveStatusExcept(trackingServiceToActivate: any) {
    const trackingServices = [
      // @ts-ignore
      window.oAvpVoucherTS,
      // @ts-ignore
      window.oAvpTrackingService,
      // @ts-ignore
      window.oAvpLotteryTS,
      // @ts-ignore
      window.oAvpTicketshopTS
    ];
    trackingServices.forEach(trackingService => {
      if (trackingService) {
        if (trackingService != trackingServiceToActivate) {
          trackingService.modifyActiveStatus(false);
        }
      }
    });
    trackingServiceToActivate.modifyActiveStatus(true);
  }
};

function CARD_FLAP() {
  const PRE_DEFINED = {
    changeFormat: function (date: any) {
      let sp = date.split(".");
      let newDate = new Date(Number(sp[2]), Number(sp[1]) - 1, Number(sp[0]));
      return moment(newDate);
    },
    diffTwoDate: function (start, end) {
      let startDate = this.changeFormat(start);
      let endDate = this.changeFormat(end);
      var diff = endDate.diff(startDate, "day");
      return diff;
    },
    convertDateFormat: function (getDate: any) {
      if (!!getDate) {
        if (typeof getDate === "string") {
          return moment(getDate).format("D.M.YYYY");
        }
        var _d = parseInt(getDate) * 1000;
        return moment(_d).format("D.M.YYYY");
      }
      return false;
    },
  };

  return {
    isOutOfStock: function (d) {
      if (d.hasOwnProperty("out_of_stock") && d.out_of_stock) {
        return { message: `Derzeit nicht auf Lager`, class: "expired" };
      }
      return false;
    },
    isAvailability: function (availability) {
      const { flap, availability_date_to, stock } = availability;
      if (/availability/i.test(flap)) {
        if (Number(stock) <= 0) {
          return { message: `Derzeit nicht auf Lager`, class: "expired" };
        }
        let today = moment().format("D.M.YYYY");
        let getAvailability =
          PRE_DEFINED.convertDateFormat(availability_date_to);
        let date_diff = PRE_DEFINED.diffTwoDate(today, getAvailability);
        return date_diff < 0
          ? { message: false, class: "" }
          : {
            message: `Angebot bis ${getAvailability}`,
            class: "availability",
          };
      }
      return false;
    },
    isStock: function (_stock_) {
      const { flap, stock, flap_status, out_of_stock } = _stock_;
      if (/stock/i.test(flap)) {
        if ((out_of_stock && flap_status) || Number(stock) <= 0) {
          return { message: `Derzeit nicht auf Lager`, class: "expired" };
        }
        if (!flap_status) {
          return { message: null, class: flap };
        }
        return {
          message: `Nur noch ${stock} Stück auf Lager!`,
          class: "stock",
        };
      }
      return false;
    }
  }
}
export default UTILS;
