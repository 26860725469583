import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService  {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): Promise<boolean> {
    return this.auth.isUserAuthenticated().then((status) => {
      if (status) {
        return true;
      }
      this.router.navigate(["/login"]);
      return false;
    });
  }
}
