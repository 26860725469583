import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL, CIM } from "src/environments/environment";
import UTILS from "src/app/shared/utils/utils";

@Injectable({
  providedIn: "root",
})
export class AuthDrupalService {
  constructor(private http: HttpClient) {}

  validateAuthCode(code: string): Promise<any> {
    const response = this.http
      .post<any>(`${API_URL}/validate/user`, { code })
      .toPromise();
    return response;
  }

  validateCIMAuthCode(code: string): Promise<any> {
    var data = JSON.stringify({
      grant_type: "authorization_code",
      code: code,
      redirect_uri: `${CIM.redirect_uri}`,
    });
    const response = this.http
      .post<any>(
        `${CIM.redirect_uri}/auth/oauth2/${CIM.realmPath}/access_token`,
        { data }
      )
      .toPromise();
    return response;
  }

  CIMUserInfo(access_token: string): Promise<any> {
    const response = this.http
      .post<any>(`${CIM.redirect_uri}/auth/oauth2/${CIM.realmPath}/userinfo`, {
        access_token,
      })
      .toPromise();
    return response;
  }

  invalidateDrupalSession(): Promise<any> {
    const response = this.http.get<any>(`${API_URL}/logout/user`).toPromise();
    return response;
  }

  validateJWT(token): Promise<any> {
    const response = this.http
      .post<any>(`${API_URL}/validate/token`, { token })
      .toPromise();
    return response;
  }
  checkKeepAlive(checkKeepAlive: any): Promise<any> {
    const { AMBaseURL } = CIM;
    try {
      const response = this.http
        .get<any>(`${AMBaseURL}/custom/keepalive.jsp?userid=${checkKeepAlive}`)
        .toPromise();
      return response;
    } catch (e) {
      console.log("keepalive error");
    }
  }

  maintenanceStatus(): Promise<any> {
    var device: any = "desktop";
    if (UTILS.detectMobile()) {
      device = "mobile";
    }
    const response = this.http
      .get<any>(`${API_URL}/maintenance/status?orientation=${device}`)
      .toPromise();
    return response;
  }
  expireCIMSession(uri): Promise<any> {
    const response = this.http.get<any>(`${uri}`).toPromise();
    return response;
  }
}
